import { FormControl } from '@angular/forms';
import { UserCompany } from '@shared/models/user/user-company.model';
import { UserBusinessOwner } from '@shared/models/user/user-business-owner.model';
import { UserPerson } from '@shared/models/user/user-person.model';
import { UserAdministrator } from '@shared/models/user/user-administrator.model';
import { BankAccount } from '@shared/components/forms/profile/bank-accounts/models/bank-account.model';

export interface UserBase {
  id: number;
  user_type: UserTypeEnum;
  activity_type: UserActivityEnum;
  phone: string;
  email: string;
  policy_agreement: boolean;
  personal_data_agreement: boolean;
  password_digest: string;
  created_at: Date;
  updated_at: Date;
  confirmation_token: string;
  confirmed_at: Date;
  confirmation_sent_at: Date;
  status: UserStatusEnum;
  scopes: UserScopes[];
  license_number: string;
  license_date: string;
  license_expiration_date: string;
  license_infinity: boolean;
  bank_accounts: BankAccount[];
}

export enum UserActivityEnum {
  WasteProducer,
  Transporter,
}

export enum UserTypeEnum {
  Administrator,
  Person,
  BusinessOwner,
  Company,
}

export enum UserStatusEnum {
  Unconfirmed,
  Confirmed,
  Blocked,
}

export interface UserScopes {
  name: string;
  permissions: UserPermission[];
}

export type UserPermission = 'read' | 'create' | 'update' | 'delete';

export interface UserFormBase {
  phone: FormControl<string>;
  email: FormControl<string>;
  license_number: FormControl<string>;
  license_date: FormControl<string>;
  license_expiration_date: FormControl<string>;
  license_infinity: FormControl<boolean>;
}

export type UserReadOnlyOmit =
  | 'id'
  | 'password_digest'
  | 'created_at'
  | 'updated_at'
  | 'confirmation_token'
  | 'confirmed_at'
  | 'confirmation_sent_at'
  | 'status'
  | 'scopes'
  | 'bank_accounts';

export type User =
  | UserCompany
  | UserBusinessOwner
  | UserPerson
  | UserAdministrator;
