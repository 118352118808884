import {
  UserBase,
  UserFormBase,
  UserTypeEnum,
} from '@shared/models/user/user.model';
import { FormControl } from '@angular/forms';

export interface UserAdministrator extends UserBase {
  user_type: UserTypeEnum.Administrator;
  administrator_name: string;
  administrator_role: UserAdministratorRole;
}

export enum UserAdministratorRole {
  Administrator,
  LegalDepartment,
  CustomerService,
  EcologyDepartment,
  MinistryOfNature,
  PolygonSecurity,
  SecurityDepartment,
  GeneralDirector,
  Accounting,
}

export interface UserAdministratorForm
  extends Omit<
    UserFormBase,
    | 'license_infinity'
    | 'license_number'
    | 'license_date'
    | 'license_expiration_date'
  > {
  administrator_name: FormControl<string>;
  administrator_role: FormControl<UserAdministratorRole>;
}
